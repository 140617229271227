<template>
  <GiftLayout is-christmas>
    <div class="gift-success gift-success--christmas">
      <h1 class="gift-success__title">
        {{ $t('giftVoicemod.successScreenTitle') }}
      </h1>
      <div class="gift-success__row">
        <div>Email</div>
        <div>{{ userEmail }}</div>
      </div>
      <hr class="gift-success__divider">
      <div
        v-if="userName"
        class="gift-success__row"
      >
        <div>{{ $t('giftVoicemod.successScreenFriend') }}</div>
        <div>{{ userName }}</div>
      </div>
      <div class="gift-success__message">
        {{ $t('giftVoicemod.successScreenMessage') }}
      </div>
      <router-link
        class="gift-success__link"
        :to="{
          path: ROUTER_PATHS.GIFT
        }"
      >
        {{ $t('giftVoicemod.successScreenActionButton') }}
      </router-link>
    </div>
  </GiftLayout>
</template>
<script>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';
import { CookieService } from '@/services/cookie';
import { ROUTER_PATHS } from '@/router/paths';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { metricsService } from  '@/metrics/metrics';
import GiftLayout from '@/components/GiftLayout/GiftLayout.vue';
import { mParticleMetric } from '../../metrics/mparticle';

export default {
    name: 'GiftSuccessView',
    components: {
        GiftLayout,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const userEmail = ref(route.query.gifting_email);
        const userName = ref(route.query.gifting_name);
        const isBeg = ref(route.query.is_beg);
        const hasValidSession = computed(() => CookieService.getCookie('access_token'));
        return {
            isBeg,
            userEmail,
            userName,
            router,
            hasValidSession,
            ROUTER_PATHS,
        };
    },
    mounted() {
        if (!this.hasValidSession) {
            this.router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.GIFT,
                },
            });
            return;
        }
        this.trackView();
    },
    methods: {
        trackView() {
            const eventData = {
                step: 'thank you',
                voicemod_system: 'account.voicemod.net',
                is_beg: !!this.isBeg,
            };
            metricsService.trackEvent(eventData);
            mParticleMetric.track(
                mParticleMetric.EVENTS.GIFT_LICENSE, eventData, mParticleMetric.EVENT_TYPE.Transaction,
            );
        },
    },
};

</script>
<style lang="scss" src="./GiftSuccessView.scss" />
